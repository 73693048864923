@media (max-width: 768px) {
    .mobile_menu_header {
        display: flex;
        justify-content: space-between;
        img {
            width: 60%;
        }
    }
    .mobile_menu {
        padding: 5%;
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: #7B7672;
        z-index: 2;

        nav {
            margin-top: 10%;
            margin-bottom: 80%;
        }
    
        ul {
            list-style-position: inside;
            padding-left: 0;
    
            li {
                margin-bottom:12px;
    
                a {
                    color:black;
                }
    
                &.selected {
                    font-weight: 600;
                    color: $primary;
    
                    span {
                        color:black;
                    }
                }
            }
    
            &.iconed {
                list-style-type: none;
    
                li {
                    position: relative;
                    padding-left:35px;
    
                    &::before {
                        position: absolute;
                        top:5px;
                        left:0;
                        background-color: #999;
                        height:18px;
                        width:18px;
                        content: "";
                    }
    
                    &.selected {
                        &::before {
                            background-color: $primary;
                        }
                    }
    
                    &.dashboard-link::before {
                        mask:url('/assets/img/icon-home.svg') no-repeat 0% 0%;
                    }
    
                    &.data-link::before {
                        mask: url('/assets/img/icon-files.svg') no-repeat 0% 0%;
                    }
    
                    &.settings-link::before {
                        mask: url('/assets/img/icon-settings.svg') no-repeat 0% 0%;
                    }
    
                    &.admin-link::before {
                        mask: url('/assets/img/icon-admin.svg') no-repeat 0% 0%;
                    }
    
                    &.workflow-link::before {
                        mask: url('/assets/img/icon-settings.svg') no-repeat 0% 0%;
                    }
                    &.payments-link::before {
                        mask: url('/assets/img/icon-payment.svg') no-repeat 0% 0%;
                    }
                }
            }
        }
    }

    .offset-menu {
        margin-left: 0;
    }

    .invoice {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-height:calc(100vh - 100px);
    
        .image-section {
            flex:3;
            background: #EEEEEE;
            align-self: stretch;
            display: flex;
            flex-direction: column; 
            height: 100vh;
            img {
                max-width: 100%;
                cursor: pointer;
            }
    
            .tab-title {
                text-transform: uppercase;
                text-align: center;
                color: #F39800;
                cursor: pointer;
    
                &:hover {
                    background: darken(#fffbf7, 10%);
                }
            }
        }
    
        .left-menu-section {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.5);
            animation: fadeIn 0.1s linear both;
            animation-iteration-count: 1;
    
            & > div {
                position: relative;
                width: 60%;
                background: #EEEEEE;
                height: 100%;
                left:-100%;
                animation: slideIn 0.1s linear both;
                animation-iteration-count: 1;
            }
        }
    
        .invoice-value {
            color: #2A1812;
        }
    
        .content-section {
            flex:5;
    
            .tab-title {
                background: #fffbf7;
                text-transform: uppercase;
                text-align: center;
                color: #F39800;
                cursor: pointer;
    
                &:hover {
                    background: darken(#fffbf7, 10%);
                }
            }
    
            .log-details {
                background-color: #fffbf7;
            }
    
            .row-hover:hover {
                background-color: #fffbf7;
                cursor: pointer;
            }
    
            .selected-log {
                background: darken(#fffbf7, 10%);
            }
    
            .tab-selected {
                background: darken(#fffbf7, 5%);
            }
    
            .main-tabs > div {
                border-bottom:1px solid #ddd;
                border-right: 1px solid #ddd;
            }
    
            .row {
                .col-md-2, .col-md-3 {
                    display: flex;
                    align-items: center;
                }
            }
        }
        .invoice-items-table {
            text-transform: uppercase;
            table-layout: fixed;
    
            th {
                background: #F8F6F3;
                opacity: 1;
                white-space: nowrap;
                font-weight: 400;
            }
    
            tr {
                .item-actions {
                    display: none;
                    position: absolute;
                    top:0;
                    left:100%;
    
                    svg {
                        width: 20px;
                        height:20px;
                    }
                }
    
                &:hover {
                    .item-actions {
                        display: block;
                    }
                }
            }
        }
    }

    .pdf {
        width: 100%;
        aspect-ratio: 4/3;
    }

    .pagination-row {
        display: flex;
        flex-direction: column;
    }
    
}