.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
  
.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}
#invoice-preview-modal {
  animation: zoom-in 0.2s linear;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.125, 0.125);
  }
  12.5% {
    transform: scale(0.125, 0.25);
  }
  25% {
    transform: scale(0.25, 0.375);
  }
  37.5% {
    transform: scale(0.375, 0.5);
  }
  50% {
    transform: scale(0.5, 0.625);
  }
  62.5% {
    transform: scale(0.625, 0.75);
  }
  75% {
    transform: scale(0.75, 0.825);
  }
  82.5% {
    transform: scale(0.825, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}